import { ApolloError } from "@apollo/client";
import { useState, useCallback, useMemo } from "react";

import { BookingInput } from "@holibob-packages/graphql-types";

import { BookingFormProviderParams } from "../contexts";
import {
    BookingQueryVariables,
    useBookingCancelMutation,
    useBookingCommitMutation,
    useBookingDeleteAvailabilityMutation,
} from "./graphql";
import { Booking, useBooking } from "./useBooking";

type BookingManagerParams = {
    bookingId: string;
} & Pick<BookingManagerReturnValue, "settings" | "permissions">;

export type BookingManagerReturnValue = {
    booking?: Booking;
    loading: boolean;
    error?: ApolloError;
    refetch: ReturnType<typeof useBooking>["refetch"];
    settings?: BookingFormProviderParams["settings"];
    permissions?: BookingFormProviderParams["permissions"];
    isCommitting: boolean;
    setInput: (input?: BookingInput | null) => void;
    bookingDeleteAvailability: (availabilityId: string) => Promise<void>;
    bookingCommit: () => Promise<void>;
    bookingCancel: () => Promise<void>;
};

export function useBookingManager({ bookingId, ...params }: BookingManagerParams): BookingManagerReturnValue {
    const [input, setInput] = useState<BookingQueryVariables["input"] | null>();
    const [isCommitting, setIsCommitting] = useState<boolean>(false);
    const [waitingForLifeCycleManager, setWaitingForLifeCircleManager] = useState<boolean>(false);

    const [bookingDeleteAvailabilityMutation, bookingDeleteState] = useBookingDeleteAvailabilityMutation();
    const [bookingCommitMutation, bookingCommitState] = useBookingCommitMutation();
    const [bookingCancelMutation] = useBookingCancelMutation();

    const {
        booking,
        refetch,
        error: bookingQueryError,
        loading: bookingQueryLoading,
        startPolling,
        stopPolling,
    } = useBooking(bookingId, input);

    const useLifecycleManager = booking?.useLifecycleManager;

    const bookingState = booking?.state;

    const stopPollingOnLifecycleManager = !booking?.isWorkflowProcessing && !waitingForLifeCycleManager;

    const stopPollingOnBookingState =
        bookingState === "CONFIRMED" || bookingState === "CANCELLED" || bookingState === "FAILED";

    const timeToStopPolling = useLifecycleManager ? stopPollingOnLifecycleManager : stopPollingOnBookingState;

    if (waitingForLifeCycleManager && booking?.isWorkflowProcessing) {
        setWaitingForLifeCircleManager(false);
    }

    if (timeToStopPolling && isCommitting) {
        stopPolling();
        setIsCommitting(false);
    }

    const pollForBookingChange = useCallback(() => {
        if (useLifecycleManager) {
            setWaitingForLifeCircleManager(true);
            setTimeout(() => {
                // just in case the lifecycle manager doesn't update the booking
                setWaitingForLifeCircleManager(false);
            }, 15000);
        }
        setIsCommitting(true);
        startPolling(1000);
    }, [startPolling, useLifecycleManager]);

    const bookingDeleteAvailability = useCallback(
        async (availabilityId: string) => {
            const bookingSelector = { id: bookingId };
            await bookingDeleteAvailabilityMutation({
                variables: { input: { bookingSelector, id: availabilityId } },
                refetchQueries: ["Booking"],
            });
        },
        [bookingDeleteAvailabilityMutation, bookingId]
    );

    const bookingCommit = useCallback(async () => {
        setInput(null);
        await bookingCommitMutation({
            variables: { bookingSelector: { id: bookingId } },
            update: (cache) => {
                cache.modify({
                    id: cache.identify({ __typename: "Booking", id: bookingId }),
                    fields: {
                        isPendingCommit: () => false,
                    },
                });
            },
            refetchQueries: ["Booking"],
        });
        pollForBookingChange();
    }, [bookingCommitMutation, bookingId, pollForBookingChange]);

    const bookingCancel = useCallback(async () => {
        await bookingCancelMutation({ variables: { input: { id: bookingId, reason: "USER CANCELLED" } } });
        pollForBookingChange();
    }, [bookingCancelMutation, bookingId, pollForBookingChange]);

    const loading = bookingQueryLoading || bookingDeleteState.loading || bookingCommitState.loading;
    const error = bookingQueryError ?? bookingDeleteState.error ?? bookingCommitState.error;

    return useMemo(() => {
        return {
            booking,
            isCommitting,
            setInput,
            loading,
            error,
            bookingDeleteAvailability,
            bookingCommit,
            bookingCancel,
            refetch,
            ...params,
        };
    }, [
        booking,
        isCommitting,
        setInput,
        loading,
        error,
        bookingDeleteAvailability,
        bookingCommit,
        bookingCancel,
        refetch,
        params,
    ]);
}

export default useBookingManager;
