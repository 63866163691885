import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { useConsumerTripEventWLSearchTriggered } from "hooks/consumerTripEvent/useConsumerTripEventWLSearchTriggered";
import { useGlobalSearchOpen } from "hooks/useGlobalSearchIsOpen";

import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()({
    button: {},
});

const GlobalSearchButtonMobile = ({ className }: { className?: string }) => {
    const open = useGlobalSearchOpen();
    const { classes, cx } = useStyles();
    const searchOpenEvent = useConsumerTripEventWLSearchTriggered();

    const handleClick = () => {
        open();
        void searchOpenEvent({ searchButtonType: "GLOBAL_SEARCH_NAVBAR_ICON" });
    };

    return (
        <IconButton onClick={handleClick} color="inherit" className={cx(className, classes.button)} size="small">
            <SearchIcon />
        </IconButton>
    );
};

const GlobalSearchButton = ({ className }: { className?: string }) => {
    return <GlobalSearchButtonMobile className={className} />;
};

export default GlobalSearchButton;
